// eslint-disable-next-line import/no-unresolved
import PropTypes from 'prop-types';
import React from 'react';

import { componentFactory } from '@powdr/utils';

import { StyledGlobalContent } from './styles';

export const GlobalContent = ({
  relationships,
  parentColorProfile,
}) => {
  const { contentNodes } = relationships;
  console.log('relationships', relationships);

  return (
    <StyledGlobalContent>
      {componentFactory(contentNodes, parentColorProfile)}
    </StyledGlobalContent>
  );
};

GlobalContent.propTypes = {
  relationships: PropTypes.shape().isRequired,
  parentColorProfile: PropTypes.string,
};

GlobalContent.defaultProps = {
  parentColorProfile: null,
};
