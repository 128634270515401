import styled, { css } from 'styled-components';

import {
  ThemeProps as Prop, Breakpoints, Components,
  FontFamily,
  FontSize,
} from '@powdr/constants';
import { getFontStyles, themeUtil } from '@powdr/utils';

const CPNT = Components.COLLAPSE_PANEL;

export const StyledCollapsePanel = styled.section`
  padding: ${({ $adjustPadding }) => (($adjustPadding) ? '15px 0' : '15px')};
  margin: 10px 0;
  background-color: ${({ $colorProfile }) => themeUtil(Prop.BACKGROUND, CPNT, $colorProfile)};

  &:first-child {
    margin: 0 0 10px;
  }

  &:last-child {
    margin: 10px 0 0;
  }
`;

export const PanelHeader = styled.header`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  padding: ${({ isContainer }) => ((isContainer) ? '0 7.5%;' : '0px;')};
`;

export const HeaderLeft = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const HeaderRight = styled.div`
  ${Breakpoints.desktopOnly(css`
    ${({ $spaceBetween }) => ($spaceBetween) && 'display: flex; justify-content: space-between; width: 55%; gap: 15px;'}
  `)}
`;

export const TitleText = styled.h3`
  color: ${({ colorProfile }) => themeUtil(Prop.HEADER, CPNT, colorProfile)};
  ${() => getFontStyles(FontFamily.PRIMARY_FONT, FontSize.MED10, undefined, undefined, CPNT, 'header')};

  ${Breakpoints.mobile(css`
    ${() => getFontStyles(FontFamily.PRIMARY_FONT, FontSize.MED10, 0.75, undefined, CPNT, 'header')};
  `)}
`;

export const CopyText = styled.p`
  ${() => getFontStyles(FontFamily.BASE_FONT, undefined, undefined, undefined, CPNT, 'body')};
  color: ${({ colorProfile }) => themeUtil(Prop.CONTENT, CPNT, colorProfile)};
  margin-bottom: 0px;
  margin-top: 7px;

  ${Breakpoints.mobile(css`
    margin-bottom: 15px;
    ${() => getFontStyles(FontFamily.BASE_FONT, undefined, 0.9, undefined, CPNT, 'body')};
  `)}

  ${Breakpoints.larger(css`
    ${() => getFontStyles(FontFamily.BASE_FONT, undefined, 1.1, undefined, CPNT, 'body')};
  `)}
`;

export const SubheaderWrapper = styled.div`
  color: ${({ colorProfile }) => themeUtil(Prop.HEADER, CPNT, colorProfile)};

  ${Breakpoints.mobile(css`
    display: ${({ $mobile }) => (($mobile) ? 'flex' : 'none')};
    margin-top: 5px;
    ${() => getFontStyles(FontFamily.BASE_FONT, undefined, 0.75, undefined, CPNT, 'body')};
  `)}

  ${Breakpoints.desktopOnly(css`
    display: ${({ $mobile }) => ((!$mobile) ? 'flex' : 'none')};
    align-items: center;
    justify-content: center;
  `)}
`;

export const ExpandButton = styled.button`
  display: flex;
  padding: 15px 20px;
  color: ${({ colorProfile }) => themeUtil(Prop.BTN_TXT, CPNT, colorProfile)};
  background-color: ${({ colorProfile }) => themeUtil(Prop.BTN_BG, CPNT, colorProfile)};
  ${() => getFontStyles(FontFamily.SECONDARY_FONT, undefined, undefined, undefined, CPNT, 'button')};

  .expand-icon {
    animation-direction: alternate;
    transition: transform ${({ $animationSpeed }) => $animationSpeed}ms ease-in-out;
    transform: rotate(0deg);
    margin-left: 5px;

    &.open {
      transform: rotate(180deg);
    }
  }

  &:hover {
    color: ${({ colorProfile }) => themeUtil(Prop.BTN_TXT_HOVER, CPNT, colorProfile)};
    background-color: ${({ colorProfile }) => themeUtil(Prop.BTN_BG_HOVER, CPNT, colorProfile)};

    svg {
      fill: ${({ colorProfile }) => themeUtil(Prop.BTN_TXT_HOVER, CPNT, colorProfile)};
      color: ${({ colorProfile }) => themeUtil(Prop.BTN_TXT_HOVER, CPNT, colorProfile)};
    }
  }

  svg {
    fill: ${({ colorProfile }) => themeUtil(Prop.BTN_TXT, CPNT, colorProfile)};
    color: ${({ colorProfile }) => themeUtil(Prop.BTN_TXT, CPNT, colorProfile)};
  }
`;

export const PanelBodyWrapper = styled.section``;

export const ComponentWrapper = styled.div`
  ${({ isContainer }) => ((isContainer) ? 'padding: 15px 7.5% 0;' : 'padding: 15px 0 0;')};
`;
